<template>
  <div class="content">
    <PageHeader :title="$t('app.audit')" />
    <div class="page-content container-fluid">
      <div class="row">
        <div class="col-lg-12 col-xl-12">
          <div class="card card-tabs">
            <div class="card-header">
              <ul class="nav nav-tabs">
                <li class="nav-item" role="presentation">
                  <router-link
                    :to="{ name: 'AdminAuditInsights' }"
                    class="nav-link"
                    replace
                    ><span class="material-symbols-outlined"> query_stats </span
                    >{{ $tc('app.stats', 2) }}</router-link
                  >
                </li>
              </ul>
            </div>
            <div class="card-body block-el">
                            <router-view
                ref="view"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import PageHeader from '@/components/rebranding/PageHeader.vue';

export default {
  name: 'AdminAnalytics',
  components: {
    PageHeader,
  },
  data() {
    return {};
  },
};
</script>

<style lang="scss" scoped>
@import '~primevue/resources/themes/saga-blue/theme.css';
@import '~primevue/resources/primevue.min.css';

.table-responsive {
  min-height: 150px;
}

/* Rebranding */
.card.card-tabs .card-header .nav.nav-tabs {
  width: 100%;
  padding: 0 1rem;
  gap: 2.5rem;
}
.card.card-tabs .card-header .nav.nav-tabs li a {
  display: flex;
  align-items: center;
  gap: 5px;
  padding: 1.4rem 0.7rem 1.3rem;
  color: var(--clr-light-2);
}
.nav.nav-tabs .nav-item .nav-link.router-link-exact-active {
  color: var(--clr-yup-purple) !important;
  border-bottom: solid 2px var(--clr-yup-purple);
}

.nav.nav-tabs .nav-item .nav-link.router-link-exact-active i {
  color: var(--clr-yup-purple) !important;
}

.card.card-tabs .card-header .nav.nav-tabs li a i {
  font-size: 24px;
}

.nav-tabs .nav-item {
  margin-bottom: -2px;
}
.nav-tabs {
  border-bottom: 2px solid rgba(210, 221, 234, 0.5);
}

.rebranding .card .opts_card i {
  font-size: 22px;
  transition: 0.5s;
  padding: 5px;
}

.rebranding .card .opts_card i:hover {
  background-color: var(--background-icon-hover);
  border-radius: 5px;
}

.calendar_form {
  position: absolute;
  right: 70px;
  width: 38px;
  opacity: 0;
}

.calendar_form:hover ~ .calendar_icon,
.calendar_form:focus-within ~ .calendar_icon {
  background-color: var(--background-icon-hover);
  border-radius: 5px;
}

.calendar_form input {
  cursor: pointer;
  width: 38px;
}
</style>
